import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Dropdown from "./Dropdown";

const FilterElem = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 30px;
  }

  &.centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    > * {
      width: calc(25% - (24px / 2));
    }

    @media screen AND (min-width: ${breakpoints.tablet}px) {
      > * {
        width: calc(25% - ((24px * 5) / 6));
      }
    }

    @media screen AND (min-width: ${breakpoints.desktop}px) {
      width: calc(25% - ((24px * 3) / 4));
    }
  }
`;

const Filter = ({ centered, filters, setFilters }) => {
  const changeCategory = (category) => {
    if (category === "All") {
      setFilters({ ...filters, category: "" });
    } else {
      setFilters({ ...filters, category });
    }
  };
  const changeRegion = (region) => {
    if (region === "All") {
      setFilters({ ...filters, region: "" });
    } else {
      setFilters({ ...filters, region });
    }
  };
  const changeMonth = (month) => {
    if (month === "All") {
      setFilters({ ...filters, month: "" });
    } else {
      setFilters({ ...filters, month });
    }
  };

  return (
    <FilterElem className={centered ? "centered" : ""}>
      <Dropdown
        title="CATEGORY"
        colored
        options={[
          "All",
          "Webinar",
          "Lunch Meeting",
          "Hybrid",
          "Evening Event",
          "Courses / Seminar",
          "Conference",
          "Others",
        ]}
        changeFunction={changeCategory}
        filterName="category"
        filters={filters}
      />
      <Dropdown
        title="REGION"
        options={["All", "Zurich", "Vaduz", "Lugano", "Lucerne/Zug", "Basel"]}
        changeFunction={changeRegion}
        filterName="region"
        filters={filters}
      />
      <Dropdown
        title="MONTH"
        options={[
          "All",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ]}
        changeFunction={changeMonth}
        filterName="month"
        filters={filters}
      />
    </FilterElem>
  );
};

export default Filter;
