import { Link } from "gatsby";
import moment from "moment-timezone";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const ItemElem = styled(Link)`
  background: ${(props) => props.color};
  border-bottom-right-radius: 60px;
  padding: 4px;
  min-width: 100%;
  min-height: 112px;
  text-decoration: none;
  border: 6px solid ${(props) => props.color};

  &:hover {
    background: white;

    .time p,
    .title h6 {
      color: ${(props) => props.color};
    }
  }

  .time {
    margin-bottom: 12px;
    p {
      font: 14px / 17px "Slate Regular";
      text-transform: uppercase;
      color: white;
    }
  }

  .title {
    h6 {
      font: 19px / 23px "Slate Medium";
      padding-right: 20px;
      color: white;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    min-height: 120px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    min-height: 140px;

    .time {
      margin-bottom: 14px;
      p {
        font: 18px / 22px "Slate Regular";
      }
    }

    .title {
      h6 {
        font: 24px / 29px "Slate Medium";
      }
    }
  }
`;

const EventItem = ({ event }) => {
  const color = event?.attributes?.type
    .toLowerCase()
    .replace("-", "")
    .replace("/", "")
    .replace(" ", "")
    .replace(" ", "");
  return (
    <ItemElem color={colors[color]} to={`/event/${event?.eventId}`}>
      <article>
        <div className="time">
          <p>
            {moment(event?.attributes?.date)
              .tz("Europe/Zurich")
              .format("dddd, DD MMMM YYYY")}
          </p>
        </div>
        <div className="title">
          <h6>{event?.attributes?.agenda[0].name}</h6>
        </div>
      </article>
    </ItemElem>
  );
};

export default EventItem;
