import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import SmallerInhalt, { InhaltElem } from "../Text/SmallerInhalt";

const Grid = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;

  > div:last-of-type {
    padding: 30px;
    order: 1;
  }

  > div:first-of-type {
    background: ${colors.blue};
    padding: 30px;
    order: 2;
    * {
      color: white;
    }
    a {
      color: ${colors.lightBlue};
    }

    > div,
    > article {
      width: 100%;
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;

    > div:first-of-type {
      padding: 20px 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: max-content;
      order: 1;

      > div,
      > article {
        max-width: 264px;
      }
    }
    > div:last-of-type {
      grid-column: span 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 30px;
      order: 2;
      padding: 0;

      > div,
      > article {
        width: 100%;
        max-width: 704px;
      }
    }
  }

  @media screen and (min-width: 1333px) {
    > div:last-of-type {
      > div,
      > article {
        max-width: 770px;
      }
    }
  }
`;

const Grid47 = ({ left, right }) => (
  <Grid>
    <div>
      <InhaltElem>{left}</InhaltElem>
    </div>
    <div>
      <InhaltElem>{right}</InhaltElem>
    </div>
  </Grid>
);

export default Grid47;
