import { graphql, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import FWBackground from "./FWBackground";

const BGContainer = styled.div`
  > section:last-of-type {
    margin-bottom: 69px;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > section:last-of-type {
      margin-bottom: 110px;
    }
  }
`;

const FWBGContainer = () => (
  <BGContainer>
    <StaticQuery
      query={graphql`
        query startseitenbild {
          startseitebild {
            data {
              content
              image
            }
          }
        }
      `}
      render={(data: any) => (
          <FWBackground
            content={data.startseitebild.data.content}
            bgColor={colors.blue}
            color="white"
            img={data.startseitebild.data.image}
            roundedCorner
          />
        )}
    />
  </BGContainer>
);

export default FWBGContainer;
