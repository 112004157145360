import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import EventsGrid from "./Events/EventsGrid";
import Filter from "./Events/Filter";

const EventElem = styled.section`
  margin-bottom: 69px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 110px;
  }
`;

const FilterReset = styled.div`
  font: 16px / 19px "Slate Book";
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  > div {
    font: 16px / 19px "Slate Book";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-top: 50px;
  }
`;

const Events = ({ centered, events }) => {
  const defaultFilters = { region: "", category: "", month: "" };
  const [filters, setFilters] = useState(defaultFilters);

  const eventsWithRelations = [];

  const filteredEvents = events.filter((event) => {
    if (filters.category !== "") {
      if (filters.category !== event.attributes.type) {
        return false;
      }
    }

    if (filters.region !== "") {
      const eventFound = event.attributes.labels.find(
        (element) => element.name === filters.region
      );
      if (!eventFound) {
        return false;
      }
    }
    if (filters.month !== "") {
      if (
        moment(event.attributes.date).tz("Europe/Zurich").format("MMMM") !==
        filters.month
      ) {
        return false;
      }
    }
    if (moment(event.attributes.end).endOf("day").isBefore()) {
      return false;
    }

    if (event.relationships.events.data.length) {
      eventsWithRelations.push({
        ...event,
      });
      return false;
    }
    return true;
  });

  const erasedDuplicates = eventsWithRelations.filter((event) => {
    const eventDate = event.attributes.date;
    let counter = 0;
    event.relationships.events.data.map((relatedEvent) => {
      if (relatedEvent.attributes.date < eventDate) {
        counter++;
      }
    });

    if (counter > 0) {
      return false;
    }
    return true;
  });

  const mergedEvents = filteredEvents.concat(erasedDuplicates);
  const sortedEvents = _.orderBy(mergedEvents, ["attributes.date"], ["asc"]);

  return (
    // define filters with useState here and pass down to filter and options components
    <EventElem>
      <Filter centered={!!centered} filters={filters} setFilters={setFilters} />
      <EventsGrid events={sortedEvents} />

      <FilterReset>
        <div>[{sortedEvents.length}] RESULTS</div>&nbsp;&nbsp;|&nbsp;&nbsp;
        <div
          onClick={() => {
            setFilters(defaultFilters);
          }}
        >
          RESET FILTER
        </div>
      </FilterReset>
    </EventElem>
  );
};

export default Events;
