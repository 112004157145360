import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";

const ColorSquare = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) => colors[props.color?.toLowerCase().replace("-", "").replace("/", "").replace(" ", "").replace(" ", "")]};
  margin-right: 6px;
  margin-top: 4.5px;
`;

const DropdownElem = styled.div`
  cursor: pointer;
  position: relative;
  min-width: 130px;

  .optionsList {
    display: none;
    list-style-type: none;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 1;
  }

  .selectionTitle {
    font: 18px / 22px "Slate Medium";
    padding: 8px 12px;
    background: ${colors.lightGray};
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .arrow {
      background: url("/assets/gestaltungselemente/arrow-down.svg");
      width: 22.3px;
      height: 12.2px;
      background-size: fill;
      margin-top: 6px;
      margin-left: 10px;
    }
  }

  &.open {
    .selectionTitle {
      .arrow {
        background: url("/assets/gestaltungselemente/arrow-up.svg");
      }
    }
    .optionsList {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 38px;
      background: white;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      li {
        font: 18px / 22px "Slate Light";
        text-transform: uppercase;
        &.active {
          font-family: "Slate Medium";
        }
        &:hover {
          background: ${colors.lightGray};
        }

        .text {
          padding: 16px 12px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
        }
        .border {
          height: 1px;
          width: calc(100% - 24px);
          background: #707070;
          margin: 0 12px;
        }
        &:last-of-type {
          .border {
            display: none;
          }
        }
      }
    }
  }

  @media screen AND (min-width: 440px) {
    min-width: 200px;
  }
`;

const Dropdown = ({
  title,
  colored = false,
  options,
  changeFunction,
  filters,
  filterName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [actualTitle, setActualTitle] = useState(title);

  useEffect(() => {
    if (filters[filterName] === "") {
      setActualTitle(title);
    }
  }, [filters]);

  return (
    <DropdownElem
      className={isOpen ? "open" : ""}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="selectionTitle">
        {actualTitle.toUpperCase()} <div className="arrow" />
      </div>
      <div className="optionsList">
        {options.map((option) => {
          const color = option.toLowerCase().replace("-", "").replace("/", "").replace(" ", "").replace(" ", "");
return(
          <li>
            <div
              className="text"
              onClick={() => {
                changeFunction(option);
                setActualTitle(option);
              }}
            >
              {colored && <ColorSquare color={color} />}
              {option}
            </div>
            <div className="border" />
          </li>
        )})}
      </div>
    </DropdownElem>
  );
};

export default Dropdown;
