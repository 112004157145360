import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";

const ButtonElem = styled.a`
  width: max-content;
  min-width: 200px;
  display: block;
  padding: 10px 10px;
  text-transform: uppercase;
  text-align: center;
  font: 23px / 28px "Slate Regular";
  background: ${(props) =>
    colors[
      props.color
        ?.toLowerCase()
        .replace("-", "")
        .replace("/", "")
        .replace(" ", "")
        .replace(" ", "")
    ]};
  margin: inherit auto;
  color: white !important;
  text-decoration: none !important;
  border-radius: 10px;
  margin: 0 auto;
  text-decoration: none !important;
  transition: all 0.2s;
  margin-top: 34px !important;

  img {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  &.smallLightBlue {
    margin: 0;
    background: ${colors.lightBlue};
    font: 18px / 22px "Slate Regular";
    &:hover {
      background: ${colors.fwBgLightBlue};
      cursor: pointer;
    }
  }

  &:hover {
    background: ${colors.lightBlue};
    cursor: pointer;
  }

  &.link,
  &.register {
    background: ${colors.orange};

    &:hover {
      background: ${colors.lightOrange};
    }
  }
`;

const Button = ({ text, type, href, color }) => {
  let btnColor = "blue";
  if (color) {
    btnColor = color;
  }
  return (
    <ButtonElem color={btnColor} className={`${type} button`} href={href}>
      {text}

      {type === "download" && (
        <>
          &nbsp;&nbsp;&nbsp;
          <img src="/assets/gestaltungselemente/download.svg" />
        </>
      )}
    </ButtonElem>
  );
};

export default Button;
