import { graphql, StaticQuery } from "gatsby";

import React from "react";
import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import SmallerMainTitle from "../components/Text/SmallerMainTitle";
import Events from "../components/Events";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import SmallerSectionTitle from "../components/Text/SmallerSectionTitle";
import Grid47 from "../components/Grid.tsx/Grid47";
import Button from "../components/Buttons/Button";
import FWBGContainer from "../components/FWBGContainer";
import PreTitle from "../components/Text/PreTitle";

const IndexPage = () => (
  <DefaultLayout>
    <Helmet>
      <title>STEP</title>
    </Helmet>
    <Page>
      <ContentWrapper>
        <PreTitle>
          WELCOME TO THE VEREIN STEP IN SWITZERLAND & LIECHTENSTEIN
        </PreTitle>

        <SmallerMainTitle>
          Let us meet on any of our upcoming events
        </SmallerMainTitle>
      </ContentWrapper>
      <StaticQuery
        query={graphql`
          query events {
            allEvent {
              nodes {
                eventId
                relationships {
                  events {
                    data {
                      id
                      attributes {
                        date
                      }
                    }
                  }
                }
                attributes {
                  admission
                  labels {
                    name
                  }
                  agenda {
                    name
                  }
                  date
                  end
                  description
                  externalTicketAgency {
                    name
                    url
                  }
                  freeText {
                    text
                    title
                  }
                  headline
                  masterEventId
                  locationId
                  shortDescription
                  showtime(locale: "de")
                  ticketing {
                    active
                    ticketCategories {
                      admission
                      available
                      info
                      ticketCategoryId
                      title
                    }
                    ticketsAvailable
                  }
                  tip
                  type
                }
              }
            }
          }
        `}
        render={(data: any) => {
          console.log("data", data.allEvent);
          return (
            <ContentWrapper>
              <Events events={data.allEvent.nodes} />
            </ContentWrapper>
          );
        }}
      />
      <SmallerSectionTitle>
        Global <b>knowledge & experience</b>
      </SmallerSectionTitle>
      <Grid47
        left={
          <>
            <h4>membership routes</h4>
            <p>
              Membership of STEP is obtainable only by examination or other test
              of professional competence.
            </p>

            <Button
              href="/join-us-cpd-awards/join-us-how-do-i-join-step"
              text="Become a Step Member"
              type="smallLightBlue"
            />
          </>
        }
        right={
          <>
            <h4>
              The Society of Trust and Estate Practitioners STEP is the
              professional body for the trust and estate profession worldwide
              and currently has over 20’000 members and students.
            </h4>
            <p>
              <b>STEP</b> Members come from the legal, accountancy, corporate
              trust, banking, insurance and related professions, and are
              involved at all levels in the planning, creation and management
              of, and accounting for, trusts and estates, executorship,
              administration and related taxes. Full Members of STEP are by
              definition the most experienced and senior practitioners in the
              field of trusts and estates.
            </p>
            <p>
              <b>STEP</b> was founded with the aim of bringing together all the
              practitioners in the field and cutting across old established
              professional boundaries. Through meetings, seminars, lectures,
              national and international conferences and the exchange of
              technical papers and reports, Members share information, knowledge
              and experience, and benefit from the network of contacts that
              membership provides.
            </p>
          </>
        }
      />

      <FWBGContainer />
    </Page>
  </DefaultLayout>
);
export default IndexPage;
